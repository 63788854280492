<template>
  <div class="footer">
    <div class="content_center">
      <div class="nav_text">
        <div @click="$router.push('/home')">首页</div>
        <div @click="$router.push({ path: '/home', hash: '#about_wl' })">
          关于威凌
        </div>
        <div @click="$router.push({ path: '/home', hash: '#product_center' })">
          产品中心
        </div>
        <div @click="$router.push({ path: '/home', hash: '#success_case' })">
          成功案例
        </div>
        <div @click="$router.push({ path: '/home', hash: '#Solution' })">
          解决方案
        </div>
        <div
          @click="$router.push({ path: '/home', hash: '#collaboration_mode' })"
        >
          合作模式
        </div>
        <div @click="$router.push({ path: '/home', hash: '#news_trend' })">
          新闻动态
        </div>
        <div @click="$router.push({ path: '/contact' })">联系我们</div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/about')">关于威凌</div>
        <div @click="$router.push({ path: '/about' })">关于我们</div>
        <div @click="$router.push({ path: '/brand' })">品牌故事</div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/product')">产品中心</div>
        <div
          v-for="(item, index) in navList"
          :key="index"
          @click="
            $router.push({
              path: '/product',
              query: { current: index, cate_id: item.id },
            })
          "
        >
          {{ item.title }}
        </div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/case')">成功案例</div>
        <div @click="$router.push({ path: '/case', hash: '#case' })">
          客户案例
        </div>
        <div @click="$router.push({ path: '/case', hash: '#partner' })">
          合作伙伴
        </div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/solution')">解决方案</div>
        <div @click="$router.push({ path: '/solution' })">解决方案</div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/cooperate')">合作模式</div>
        <div @click="$router.push({ path: '/cooperate', hash: '#daili' })">
          代理商
        </div>
        <div @click="$router.push({ path: '/cooperate', hash: '#jingxiao' })">
          经销商
        </div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/news')">新闻动态</div>
        <div @click="$router.push({ path: '/news' })">新闻动态</div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/contact')">联系我们</div>
        <div @click="$router.push({ path: '/contact' })">联系我们</div>
      </div>
      <div class="qrCode tac">
        <img :src="systemInfo.wx_image" alt="" />
        <!-- <img src="@/assets/images/QR_code.png" alt="" /> -->
        <div>扫一扫关注公司公众号</div>
      </div>
      <div class="qrCode tac">
        <img :src="systemInfo.wx_mini_image" alt="" />
        <!-- <img src="@/assets/images/QR_code.png" alt="" /> -->
        <div>扫一扫打开公司小程序</div>
      </div>
    </div>

    <div class="bottom_line">
      <div>Copyright @ 2024 浙江威凌建筑科技有限公司 All Rights Reserved</div>
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          {{ systemInfo.beian }}
        </a>
      </div>
    </div>

    <!-- <div class="backTop" :class="whether ? 'block' : ''" @click="goBack">
      <img src="@/assets/images/backTop.png" alt="" />
    </div> -->
  </div>
</template>

<script>
window.onload = function () {
  window.scrollTo(0, 0);
};

import { mapState } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {
      whether: false,
    };
  },

  computed: {
    ...mapState("home", ["systemInfo", "navList"]),
  },

  methods: {
    goBack() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  width: 100%;
  height: 562px;
  background: #131d4b;

  .content_center {
    position: absolute;
    top: 25%;
    left: 9%;
    display: flex;

    .nav_text {
      margin-right: 80px;
    }

    .nav_text div:first-child {
      font-family: Source Han Sans CN;
      font-weight: normal;
      font-size: 16px;
      color: #ffffff;
      // cursor: pointer;
      margin-bottom: 20px;
    }

    .nav_text div:not(:first-child) {
      font-family: Source Han Sans CN;
      font-weight: normal;
      font-size: 14px;
      color: #ffffff;
      line-height: 42px;
      opacity: 0.3;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }

    .nav_text div:not(:first-child):hover {
      font-weight: 700;
      transform: translateX(10px);
      opacity: 1;
    }

    .qrCode {
      font-family: Source Han Sans CN;
      font-weight: normal;
      font-size: 14px;
      color: #ffffff;
      margin-left: 40px;

      img {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
        transition: all 0.8s ease-out;
        cursor: pointer;
      }

      img:hover {
        transform: scale(1.05);
      }
    }
  }

  .bottom_line {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 14px;
    color: #ffffff;
    border-top: 1px solid rgba(253, 253, 253, 0.5);
    text-align: center;
    line-height: 60px;
    div:nth-child(n + 2) {
      margin-left: 20px;
    }

    a {
      color: #fff;
    }
  }

  .backTop {
    position: absolute;
    right: 4%;
    top: 30%;
    transition: opacity 3s ease-in-out;
    cursor: pointer;
    z-index: 999;

    img {
      width: 70px;
    }
  }

  .backTop:hover {
    transform: scale(1.05);
  }
}

@media (max-width: 768px) {
  .footer {
    height: auto;
  }

  .content_center {
    display: none !important;
  }

  .footer .bottom_line {
    position: relative;
    height: auto;
    font-size: 10px;
    padding: 10px;
  }

  .bottom_line {
    flex-direction: column;
    line-height: 20px !important;
  }

  .backTop {
    img {
      width: 30px !important;
    }
  }
}
</style>
