import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 初始化CSS
import "normalize.css";

// 导入重置CSS文件
import "./style/css/global.css";
import "./style/reset.scss";

// 导入全局组件
import "@/components";
import "@/style/css/fonts/fonts.css";

// 导入element-ui组件

import {
  Carousel,
  CarouselItem,
  Cascader,
  DatePicker, Image, InputNumber,
  Message,
  pagination,
  Radio,
  Select,
  Switch
} from 'element-ui';

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Cascader)
Vue.use(Select)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(pagination)
Vue.use(Image)
Vue.prototype.$message = Message
Vue.config.productionTip = false;


import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
