import Vue from 'vue'

// 导入组件 并注册组件
import FixIcon from './FixIcon'
import ImagePreview from './ImagePreview'

// 全局注册导航分类组件
Vue.component('FixIcon', FixIcon)
Vue.component('ImagePreview', ImagePreview)

// 全局注册事件处理中心
Vue.prototype.$bus = new Vue()