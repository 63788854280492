<!-- ImagePreview.vue -->
<template>
  <div v-if="visible" class="preview-overlay" @click="close">
    <img :src="src" class="preview-image" />
  </div>
</template>

<script>
export default {
  props: ["src", "visible"],
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style scoped>
.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.preview-image {
  max-width: 90%;
  max-height: 90%;
}
</style>
