// 封装请求库
import Axios from "axios";
// 按需引入element的消息弹窗
import CryptoJS from 'crypto-js';
import { Message } from "element-ui";


// 通过create创建axios实例
const request = Axios.create({
  // 配置公共请求地址
  // baseURL: "http://weiling.hzgdwl.com/api/",
  baseURL: "https://api.winin.net.cn/api/",
  // 请求超时时间
  timeout: 5000,
});

function hashSha256(val) {
  return CryptoJS.SHA256(val).toString();
}

function md5HexHash(val) {
  const hash = CryptoJS.MD5(val);
  return hash.toString(CryptoJS.enc.Hex);
}

function generateEncryptSafecode(timestamp) {
  // const timestamp = new Date().getTime();
  const Key = "weiling";
  const Secret = "GxvYghSdCIyZiwRW3RSMPQPgCzDQD95I";
  const hash = md5HexHash(md5HexHash(hashSha256(Key) + timestamp + Secret));
  return hash;
}

// 定义请求拦截器
request.interceptors.request.use(
  (config) => {
    let time = new Date().getTime();
    // 设置公共的请求头参数，例如：Authorization
    config.headers.ti = time
    // 或者其他你需要设置的公共请求头
    config.headers.requestid = generateEncryptSafecode(time);
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 定义响应拦截器
request.interceptors.response.use(
  // 响应成功
  async (res) => {
    // 取出响应结果
    const data = res.data;
    // 判断响应码
    if (data.code !== 200) {
      Message(data.msg || "响应错误");
      return Promise.reject(new Error(data.msg));
    }

    return data.data || data;
  },
  // 响应失败
  (err) => {
    // console.log(err, '定义响应拦截器');
    // Message(err)
    new Promise(new Error(err));
  }
);

// 导入创建的axios实例
export default ({ method, url, data }) => {
  return request({
    method,
    url,
    // 如果方法为get则使用 params传值 否则使用data传值
    [method.toLowerCase() === "get" ? "params" : "data"]: data,
  });
};
