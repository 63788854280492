<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted: function () {
    this.$store.dispatch("home/indexSystem");
    this.$store.dispatch("home/indexAll");
    this.$store.dispatch("home/aboutDetail");
    this.$store.dispatch("home/cateList");
  },

  methods: {},
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  .content {
    padding-top: 52px;
  }
}
</style>
