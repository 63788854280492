<template>
  <div>
    <!-- 网页端顶部 -->
    <div
      class="header dis_jus hidden-xs"
      :class="whether ? 'on' : ''"
      @mouseover="over"
      @mouseout="handleScroll"
    >
      <div class="logo1" @click="$router.push('/home')">
        <!-- <img src="@/assets/images/logo.png" alt="" /> -->
        <img :src="whether ? systemInfo.logo : systemInfo.logo2" alt="" />
      </div>

      <div class="rightText dis_aic">
        <div class="navlist dis_jus">
          <div class="text firNav">
            <div
              class=""
              :class="whether ? 'active' : ''"
              @click="$router.push('/home')"
            >
              首页
            </div>

            <div class="sedNav">
              <div class="">
                <div
                  class="sedNav_1"
                  @click.stop="
                    $router.push({ path: '/home', hash: '#about_wl' })
                  "
                >
                  关于威凌
                </div>
                <div
                  class="sedNav_1"
                  @click.stop="
                    $router.push({ path: '/home', hash: '#product_center' })
                  "
                >
                  产品中心
                </div>
                <div
                  class="sedNav_1"
                  @click.stop="
                    $router.push({ path: '/home', hash: '#success_case' })
                  "
                >
                  成功案例
                </div>
                <div
                  class="sedNav_1"
                  @click.stop="
                    $router.push({ path: '/home', hash: '#Solution' })
                  "
                >
                  解决方案
                </div>
                <div
                  class="sedNav_1"
                  @click.stop="
                    $router.push({ path: '/home', hash: '#collaboration_mode' })
                  "
                >
                  合作模式
                </div>
                <div
                  class="sedNav_1"
                  @click.stop="
                    $router.push({ path: '/home', hash: '#news_trend' })
                  "
                >
                  新闻动态
                </div>
                <div
                  class="sedNav_1"
                  @click.stop="$router.push({ path: '/home' })"
                >
                  联系我们
                </div>
              </div>
            </div>
          </div>
          <div class="text firNav">
            <div
              class=""
              :class="whether ? 'active' : ''"
              @click="$router.push('/about')"
            >
              关于威凌
            </div>
            <div class="sedNav">
              <div class="">
                <div
                  class="sedNav_1"
                  @click.stop="$router.push({ path: '/about' })"
                >
                  关于我们
                </div>
                <div
                  class="sedNav_1"
                  @click.stop="$router.push({ path: '/brand' })"
                >
                  品牌故事
                </div>
              </div>
            </div>
          </div>
          <div class="text firNav">
            <div
              class=""
              :class="whether ? 'active' : ''"
              @click="$router.push('/product')"
            >
              产品中心
            </div>
            <div class="sedNav">
              <div class="">
                <div
                  class="sedNav_1"
                  v-for="(item, index) in navList"
                  :key="index"
                  @click.stop="goProduct(index, item.id)"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="text firNav">
            <div
              class=""
              :class="whether ? 'active' : ''"
              @click="$router.push('/case')"
            >
              成功案例
            </div>
            <div class="sedNav">
              <div class="">
                <div
                  class="sedNav_1"
                  @click.stop="$router.push({ path: '/case', hash: '#case' })"
                >
                  客户案例
                </div>

                <div
                  class="sedNav_1"
                  @click.stop="
                    $router.push({ path: '/case', hash: '#partner' })
                  "
                >
                  合作伙伴
                </div>
              </div>
            </div>
          </div>
          <div class="text firNav">
            <div
              class=""
              :class="whether ? 'active' : ''"
              @click="$router.push('/solution')"
            >
              解决方案
            </div>
            <div class="sedNav">
              <div class=""></div>
            </div>
          </div>
          <div class="text firNav">
            <div
              class=""
              :class="whether ? 'active' : ''"
              @click="$router.push({ path: '/cooperate' })"
            >
              合作模式
            </div>
            <div class="sedNav">
              <div class="">
                <div
                  class="sedNav_1"
                  @click.stop="
                    $router.push({ path: '/cooperate', hash: '#daili' })
                  "
                >
                  代理商
                </div>
                <div
                  class="sedNav_1"
                  @click.stop="
                    $router.push({ path: '/cooperate', hash: '#jingxiao' })
                  "
                >
                  经销商
                </div>
              </div>
            </div>
          </div>
          <div class="text firNav">
            <div
              class=""
              :class="whether ? 'active' : ''"
              @click.stop="$router.push({ path: '/news' })"
            >
              新闻动态
            </div>
            <div class="sedNav">
              <div class=""></div>
            </div>
          </div>
          <div class="text firNav">
            <div
              class=""
              :class="whether ? 'active' : ''"
              @click.stop="$router.push({ path: '/contact' })"
            >
              联系我们
            </div>
            <div class="sedNav">
              <div class=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="phone visible-xs">
      <div class="phoneCon">
        <div class="logo1" @click="$router.push('/home')">
          <img src="@/assets/images/logo.png" alt="" />
          <!-- <img :src="systemInfo.logo1" alt="" /> -->
        </div>
        <div class="phoneMenu" @click="menuShow()">
          <span></span><span></span><span></span>
        </div>
        <div class="phoneList" :class="{ menuShow: menu }">
          <div
            class=""
            @click="
              menu = false;
              $router.push('/home');
            "
          >
            首页
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push('/about');
            "
          >
            关于威凌
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push('/brand');
            "
          >
            品牌故事
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push('/product');
            "
          >
            产品中心
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push('/case');
            "
          >
            成功案例
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push('/solution');
            "
          >
            解决方案
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push({ path: '/cooperate' });
            "
          >
            合作模式
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push({
                path: '/news',
              });
            "
          >
            新闻动态
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push({
                path: '/contact',
              });
            "
          >
            联系我们
          </div>
        </div>
      </div>
    </div>

    <div class="backTop" :class="whether ? 'block' : ''" @click="goBack">
      <img src="@/assets/images/backTop.png" alt="" />
    </div>
  </div>
</template>

<script>
window.onload = function () {
  window.scrollTo(0, 0);
};
import { mapState } from "vuex";

export default {
  components: {},
  props: {},
  computed: {
    ...mapState("home", ["systemInfo", "navList"]),
  },
  data() {
    return {
      menu: false,
      whether: true,
    };
  },

  mounted() {
    // console.log(this.$router);
  },

  created() {
    this.screenHeight = window.innerHeight;
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    over() {
      this.whether = true;
    },

    menuShow() {
      if (this.menu) {
        this.menu = false;
      } else {
        this.menu = true;
      }
    },

    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      // if (scrollTop > 1000) {
      //   this.whether = true;
      // } else {
      //   this.whether = false;
      // }
    },

    goBack() {
      window.scrollTo(0, 0);
    },

    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },

    goProduct(current, id) {
      this.$router.push({
        name: "product",
        query: { current: current, cate_id: id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 110px;
  padding: 0 150px;

  .rightText {
    width: 1240px;
    height: 110px;
    box-sizing: border-box;

    .navlist {
      width: 1138px;
    }

    .text {
      font-family: HarmonyOS Sans SC;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 110px;
      // padding: 0 80px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    .firNav {
      position: relative;
    }

    .firNav::after {
      content: "";
      display: block;
      width: 80%;
      height: 2px;
      background-color: #073b6d;
      position: absolute;
      left: 10%;
      bottom: 30px;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out;
    }

    .firNav:hover::after,
    .text.on.firNav::after {
      transform: scaleX(1);
    }

    .active {
      color: #333333;
    }

    .text:hover .firNav {
      color: #073b6d;
    }

    .text:hover .sedNav {
      display: block;
      opacity: 1;
      filter: alpha(opacity=100);
      visibility: visible;
    }

    .sedNav {
      position: absolute;
      top: 110px;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      background-color: #fff;
      // border-top: 1px solid rgba(0, 0, 0, 0.1);
      // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      text-align: center;
      opacity: 0;
      filter: alpha(opacity=0);
      visibility: hidden;
      transition: all 0.3s ease-in-out;

      .sedNav_1 {
        font-size: 16px;
        color: #333333;
        line-height: 60px;
        padding: 0 30px;
        transition: all 0.3s ease-in-out;
      }
    }

    .sedNav .sedNav_1:hover {
      color: #073b6d;
      background: rgba($color: #073b6d, $alpha: 0.3);
    }
  }

  .header.on .headerNav > li > a::after {
    background-color: #000;
  }
}

.on {
  background: #fff;
  // box-shadow: 0px 0px 5px 0 #333333;
}

.logo1 img {
  width: 118px;
  height: 58px;
  cursor: pointer;
}

.backTop {
  position: fixed;
  right: 4%;
  bottom: 10%;
  transition: opacity 3s ease-in-out;
  display: none;
  cursor: pointer;
  z-index: 999;

  img {
    width: 96px;
    height: 96px;
    animation: moveUpDown 3s ease-in-out infinite;
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.block {
  display: block;
}

// .backTop:hover {
//   transform: scale(1.05);
// }

.visible-xs {
  display: none !important;
}

@media (max-width: 768px) {
  .visible-xs {
    display: block !important;
  }

  .hidden-xs {
    display: none !important;
  }

  .phone {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 52px;
    background: #ffffff;
    padding: 0 20px;
    z-index: 999;
  }

  .phoneCon {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .phoneCon .logo1 img {
    width: 40px;
    height: auto;
  }

  .phoneMenu {
    width: 22px;
  }

  .phoneMenu span {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #000;
  }

  .phoneMenu span:nth-child(n + 2) {
    margin-top: 5px;
  }

  .phoneList {
    position: absolute;
    top: 52px;
    right: 0;
    width: 80%;
    height: 100vh;
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    padding: 0 20px;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 2;
  }

  .phoneList.menuShow {
    // height: 328px;
    transform: translateX(0);
  }

  .phoneList div {
    width: 100%;
    font-size: 16px;
    color: #333;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px dotted #e5e5e5;
  }

  .backTop img {
    width: 40px;
    height: 40px;
  }

  .phoneCon .logo1 img {
    width: 70px;
  }
}
</style>
