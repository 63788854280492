<template>
  <div class="layout-view">
    <AppHeader></AppHeader>
    <router-view></router-view>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/AppHeader";

export default {
  name: "Layout",
  components: { AppHeader, AppFooter },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped></style>
