// 导入API接口函数
import { aboutDetail, cateList, indexAll, indexSystem } from "@/api";

// 首页模块
export default {
  namespaced: true, // 命名空间
  state: {
    systemInfo: {},
    indexAllInfo: {},
    aboutInfo: {},
    navList: []
  },

  mutations: {
    SET_SYSTEM_INFO(state, info) {
      state.systemInfo = info
    },

    INDEX_ALL_INFO(state, info) {
      state.indexAllInfo = info
    },

    ABOUT_INFO(state, info) {
      state.aboutInfo = info
    },

    NAV_LIST(state, info) {
      state.navList = info
    }
  },

  actions: {
    // 基础配置
    async indexSystem({ commit }) {
      let res = await indexSystem();
      // console.log(res, '基础配置');
      commit("SET_SYSTEM_INFO", res);
    },

    // 首页全部数据
    async indexAll({ commit }) {
      let res = await indexAll();
      // console.log(res, '首页全部数据');
      commit("INDEX_ALL_INFO", res);
    },

    // 关于我们和品牌故事
    async aboutDetail({ commit }) {
      let res = await aboutDetail();
      // console.log(res, '关于我们和品牌故事');
      commit("ABOUT_INFO", res);
    },

    // 获取产品分类列表
    async cateList({ commit }) {
      let res = await cateList();
      // console.log(res, '获取产品分类列表');
      commit("NAV_LIST", res);
    },


  },
};
