// 所有请求接口的集合
import request from "@/utils/request";

// 基础配置
export const indexSystem = () => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "index/config",
  });
};

// banner
export const banner = (data) => {
  return request({
    method: "GET",
    url: "Banner/getList",
    data
  });
};

// 首页全部数据 
export const indexAll = (data) => {
  return request({
    method: "GET",
    url: "index/all",
    data
  });
};

// 合作模式 
export const cooperation = (data) => {
  return request({
    method: "GET",
    url: "Cooperation/getList",
    data
  });
};

// 新闻列表 
export const news = (data) => {
  return request({
    method: "GET",
    url: "News/getPageList",
    data
  });
};

// 新闻详情
export const newsDetail = (data) => {
  return request({
    method: "GET",
    url: "News/getDetail",
    data
  });
};

// 关于我们和品牌故事
export const aboutDetail = (data) => {
  return request({
    method: "GET",
    url: "About/getDetail",
    data
  });
};

// 获取合作伙伴列表
export const getPartnersList = (data) => {
  return request({
    method: "GET",
    url: "Cases/getPartnersList",
    data
  });
};

// 案例详情
export const caseDetail = (data) => {
  return request({
    method: "GET",
    url: "Cases/getDetail",
    data
  });
};

// 获取解决方案
export const solutionInfo = () => {
  return request({
    method: "GET",
    url: "Solution/getDetail",
  });
};

// 获取产品分类列表
export const cateList = () => {
  return request({
    method: "GET",
    url: "Product/getCateList",
  });
};

// 获取产品分页列表
export const product = (data) => {
  return request({
    method: "GET",
    url: "Product/getPageList",
    data
  });
};

// 获取产品详情
export const productDetail = (data) => {
  return request({
    method: "GET",
    url: "Product/getDetail",
    data
  });
};


// 获取案例列表
export const caseList = (data) => {
  return request({
    method: "GET",
    url: "Cases/getList",
    data
  });
};

//  提交留言
export const sendMessage = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "POST",
    url: "Message/sendMessage",
    data
  });
};